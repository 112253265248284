import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { Link, useParams } from "react-router-dom";

import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loader from "../../loader/Loader";
import styles from "./JobDetails.module.scss";
import "./JobDetails.css";
import { getJob } from "../../../api/sinapsi.services";
import Navigator from "../../navigator/Navigator";
import jobLevel from "../../../assets/joblevel.png";
import locationIcon from "../../../assets/location.png";
import jobTypeIcon from "../../../assets/jobtype.png";
import remoteJob from "../../../assets/remotejob.png";
import cityIcon from "../../../assets/city-solid.svg";
import { useConfig } from "../../ConfigContext";

export default function JobDetails() {
  const config = useConfig();
  const { t } = useTranslation();
  const { jobId } = useParams();
  const links = [
    { link: "/jobs", label: "jobListing" },
    { link: `/jobs/${jobId}`, label: "jobDetails" },
  ];
  const [jobDetails, setJobDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getJob(config, jobId).then((res) => {
      setJobDetails({
        ...res,
        isFullTime: res.contractDuration === "Full time",
      });
      setLoading(false);
    });
  }, [jobId]);

  // function buildDescription(details) {
  //   console.log(jobDetails);
  //   return details.description?.paragraphs.map((paragraph) => {
  //     if (paragraph.paragraphType === 0) {
  //       const lines = paragraph.content.split(/\r?\n/);
  //       return (
  //         <div>
  //           <h4 className="mt-4">{paragraph.title}</h4>
  //           {lines.map((line) => (
  //             <div className="mt-1 text-break text-justify">{line}</div>
  //           ))}
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div>
  //           <h4 className="mt-4">{paragraph.title}</h4>
  //           <ul>
  //             {paragraph.items.map((item) => (
  //               <li className="mt-1 text-break text-justify">{item}</li>
  //             ))}
  //           </ul>
  //         </div>
  //       );
  //     }
  //   });
  // }

  function BuildLocations() {
    return Object.keys(jobDetails.locations).map((key) => {
      return(
      <div className={"location"}>
        <div className={"panelTitle country"}>
          <img src={locationIcon} className={"globeImage"}></img>
          &nbsp;
          {key}
        </div>
        {jobDetails.locations[key].map((item) => {
          return(
            <div className={"cityName"}>
              <img src={cityIcon} className={"cityIcon"}></img>
              &nbsp;
              {item}
            </div>
          )
        })}
        </div>
      )
    });
  }

  return (
    <div>
      <Header
        isJobHeader={true}
        jobTitle={jobDetails.title}
        isRemote={jobDetails.workTypes}
        isFullTime={jobDetails.isFullTime}
        canApply={jobDetails.canApply}
        jobId={jobId}
      ></Header>
      {loading ? (
        <Loader loading={loading}></Loader>
      ) : (
        <div>
          <Container className="mb-4">
            <Navigator links={links}></Navigator>
            <Row>
              <Col className="leftPanel">
                <div className={`${styles.container} pl-2`}>
                  <div>
                    <h3>{t("jobInfo")}</h3>
                    <h4>{jobDetails.jobInfoTitle}</h4>
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>
                        {t("experienceLevel")}
                      </div>
                      <div className={"panelTitle"}>
                        <img src={jobLevel}></img>
                        &nbsp;
                        <div>{jobDetails.seniority}</div>
                      </div>
                    </div>
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>{t("workTime")}</div>
                      <div className={"panelTitle"}>
                        <img src={jobTypeIcon}></img>
                        &nbsp;
                        {jobDetails.isFullTime ? t("fullTime") : t("partTime")}
                      </div>
                    </div>
                    {jobDetails.locations ?
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>{t("location")}</div>
                      {BuildLocations()}
                    </div>
                    : null}
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>{t("workType")}</div>
                      <div className={"panelTitle"}>
                        <img src={remoteJob}></img>{" "}
                        &nbsp;
                        {jobDetails.workTypes?.map((w) => (
                          <span>{w}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={9} className={styles.left}>
                <div className={styles.jobSection}>
                <h4>
                  What is the impact you want to make?
                </h4>
                <p>
                  Unleash your biggest strengths, apply skills & knowledge, learn new things, connect with your peers and build your career with us!
                </p>
                

                <h3>
                  Why rinf.tech?
                </h3>
                <h4> 
                  #EngineerOfTheFuture, #PeopleofManyTalents 
                </h4>
                <ul>
                  <li className="mt-1 text-break text-justify">
                    At rinf.tech, you’ll encounter friendly people who are eager to explore and reinvent the world of technology. 
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Our teams and partners are our greatest strength - together, we are resilient in this fast-paced environment. 
                  </li>
                  <li className="mt-1 text-break text-justify">
                    We encourage ideas - we like to share and learn from each other. We’re all in for curious & ambitious people. 
                  </li>
                </ul>
                <h4>
                  #GrowOpportunities
                </h4>
                <ul>
                  <li className="mt-1 text-break text-justify">
                    We continuously invest in developing core teams focused on technologies like Blockchain, AI, and IoT. Based on your expertise, you can join one of these teams as a technical expert 
                    <a
                      target="_blank"
                      href="https://www.rinf.tech/careers/core-blockchain-and-ai-teams/"
                      className="fw-bold nohover dark d-inline-block" rel="noreferrer"
                      style= {{"color": "rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important"}}
                    >
                    &nbsp;
                    www.rinf.tech/careers/core-blockchain-and-ai-teams/
                    &nbsp;
                  </a>
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Our Technical Management team, consisting of Technical Directors, Solutions Architects, Delivery Managers and Project Managers, possesses a robust technical background. Many of our team members have advanced to strategic roles through internal promotions. 
                  </li>
                  <li className="mt-1 text-break text-justify">
                    In a state of mutual willingness to share & grow, our RINFers commit to a minimum tenure of 2.5 years on a project. 
                  </li>
                </ul>
                <h4>
                  #EngineeringExcellence
                </h4>
                <p className="text-justify">
                  We're still small enough to be flexible and agile, and big enough to scale fast. No matter what our role is within Technology, we always apply four simple principles:
                </p>
                <ul>
                  <li className="mt-1 text-break text-justify">
                    Fail fast, learn fast: we experiment, we iterate, we know when to stop and we don't repeat the same mistakes
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Re-imagine, blend, re-use: we innovate building on existing concepts and exploring unconventional combinations.
                  </li>
                  <li className="mt-1 text-break text-justify">
                    The right technology stack for the right problem: we don't force technology choices just because we know them; our focus is on solving problems, not on pushing predefined stacks.
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Data whispers louder than opinions: we make informed decisions, avoiding the traps of personal biases and assumptions
                  </li>
                </ul>
                <h4>
                  #Innovation
                </h4>
                <ul>
                  <li className="mt-1 text-break text-justify">
                    We invest in R&D and partnerships with industry leaders, institutions, and universities to remain at the forefront of technological advancements and provide our team members with unique opportunities for professional growth and collaboration with the best in the field. Some examples include NXP for developing the ecosystem surrounding NXP technologies, Intel for additional support in delivering innovative services and solutions, Purdue University on Digital Twins, and the Romanian National Institute for Research and Development in Microtechnologies - IMT Bucharest, for IoT solutions.
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Adapta Robotics is a successful spin-off born through an R&D project within rinf.tech
                    <a
                      target="_blank"
                      href="https://www.adaptarobotics.com/"
                      className="fw-bold nohover dark d-inline-block" rel="noreferrer"
                      style= {{"color": "rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important"}}
                    >
                    &nbsp;
                    www.adaptarobotics.com/
                  </a>
                  </li>
                </ul>
                

                <h3>
                  Why do we do what we do?
                </h3>
                <p className="text-justify">
                  We inspire one another to share our tech-works in this amazing and abundant world. So we became developers, innovators, thinkers, software builders, and hardware makers!
                </p>
                

                <h3>
                  Our Vision!
                </h3>
                <p className="text-justify">
                  Founded in 2006 with 650+ engineers & global presence (8 delivery centers in Europe & North America) we strive to become a leading East-European technology partner for growing organizations in need of digital transformation of their products and services!
                </p>
                

                {
                  jobDetails.description?.Paragraphs[0].items && 
                  <>
                    <h3>
                      What you’ll do
                    </h3>
                    <ul>
                      {jobDetails.description?.Paragraphs[0].items.map((item) => (
                        <li className="mt-1 text-break text-justify">{item}</li>
                      ))}
                    </ul>
                  </>
                }
                

                {
                  jobDetails.description?.Paragraphs[1].items && 
                  <>
                    <h3>
                      What you need to be successful
                    </h3>
                    <ul>
                      {jobDetails.description?.Paragraphs[1].items.map((item) => (
                        <li className="mt-1 text-break text-justify">{item}</li>
                      ))}
                    </ul>
                  </>
                }
                

                <h3>
                  What we offer you to be successful
                </h3>
                <ul>
                  <li className="mt-1 text-break text-justify">
                    Flexible working environment, ability to work remotely or in a hybrid model, depending on your project requirements.
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Work with worldwide technology leaders - We partner and deliver technology to industry giants like Intel, NXP, Panasonic, Visteon, Carrefour, Windriver, Kardex, METRO Digital, and many others.
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Medical Health Subscription for you and your family: Choose from Regina Maria, Medicover, or Medlife!
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Mental Health Online Resources through the Hilio Wellbeing Platform
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Flexible benefits worth 1200 RON gross per month - you can choose the ones that suit your preference
                  </li>
                  <li className="mt-1 text-break text-justify">
                    We have profit shares schemed that enable you to financially benefit from the new business you identify
                  </li>
                  <li className="mt-1 text-break text-justify">
                    We have premium referral bonus for each successful recommendation you make - (1000-3000 EUR NET for Middle/Seniors)
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Enjoy our vibrant offices in Romania's top IT hubs – Bucharest, Timisoara, Cluj, and Iasi, perfect for when you want to grab a coffee with your colleagues.
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Community – No man is an island, we stay connected through ongoing learning, project rotation, idea incubation, as well as through our Football Team, Townhalls, and Fun Thursday Office Day!
                  </li>
                </ul>
                

                <h3>
                  Next Steps for you!
                </h3>
                <ul>
                  <li className="mt-1 text-break text-justify">
                    Apply
                  </li>
                  <li className="mt-1 text-break text-justify">
                    CV screening
                  </li>
                  <li className="mt-1 text-break text-justify">
                    HR Interview
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Technical Interview
                  </li>
                  <li className="mt-1 text-break text-justify">
                    Offer presented by our VP of Strategic Projects & CoFounder
                  </li>
                </ul>
                

                <h4>
                  Meet us!
                </h4>
                <p className="text-justify">
                  Let's meet! We invite you to drop by anytime for a tour of our office, without any commitment. 
                </p>
                

                

                <h4>
                  Join the #PeopleOfManyTalents #EngineerOfTheFuture
                </h4>
                
                
                  {/* <h3>{t("jobDescription")}</h3>
                  <p className="text-justify">
                    We are thrilled to have you as our candidate! Now, applying is a breeze with our streamlined application process!
                    
                    {jobDetails.abstract}
                  </p> */}
                </div>
                {/* {buildDescription(jobDetails)} */}
                <div className="mt-4 jd_section">
                  {jobDetails.canApply ? (
                    <Link
                      className="nohover d-inline-block me-3"
                      to={`/jobs/${jobId}/apply`}
                    >
                      <button>{t("interested")}</button>
                    </Link>
                  ) : null}
                  <Link className="nohover d-inline-block" to={`/jobs`}>
                    <button className="third">{t("goback")}</button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer showJobs={true}></Footer>
        </div>
      )}
    </div>
  );
}
